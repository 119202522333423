<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:46:53
-->
<template>
	<div class="studentManHour">
		<div class="studentManHour-data">
			<div v-if="!showBatchSearch" class="">
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">学号</span>
						<el-input class="common-row-ipt" placeholder="请输入学号" v-model="searchForm.studentId" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">姓名</span>
						<el-input class="common-row-ipt" placeholder="请输入姓名" v-model="searchForm.name" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">注册号</span>
						<el-input class="common-row-ipt" placeholder="请输入注册号" v-model="searchForm.regNum" />
					</div>
				</div>
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">活动名称</span>
						<el-input class="common-row-ipt" placeholder="请输入活动名称" v-model="searchForm.activityName" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">活动时间</span>
						<el-date-picker class="common-row-ipt" :clearable="false" value-format="yyyy-MM-dd"
							@change="dateChange" v-model="searchForm.time" type="daterange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</div>
					<div class="common-row-item flex-between">
						<div class="flex-item">
							<el-button @click="search" type="primary">查询</el-button>
							<el-button @click="reset">重置</el-button>
						</div>
						<div class="common-row-btn">
							<el-button @click="downLoadFillList">导出数据</el-button>
							<el-button @click="actionFn('showBatchSearch')">批量查询</el-button>
						</div>
					</div>
				</div>
				<!-- <div class="common-row flex-between">
					<div class="flex-item">
						<el-button @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
					<div class="common-row-btn">
						<el-button @click="downLoadFillList">导出数据</el-button>
						<el-button @click="actionFn('showBatchSearch')">批量查询</el-button>
					</div>
				</div> -->
			</div>
			<div v-else class="">
				<div class="common-row flex-start">
					<div class="common-row-item">
						<span class="common-row-label">学号</span>
						<el-input type="textarea" class="common-row-textarea" placeholder="请输入学号，多个学号之间用英文“,”隔开"
							v-model="searchForm.studentId" />
					</div>
				</div>
				<div class="common-row flex-between">
					<div class="flex-item">
						<el-button @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
					<div class="common-row-btn">
						<el-button @click="downLoadFillList">导出数据</el-button>
						<el-button @click="actionFn('hideBatchSearch')">取消批量查询</el-button>
					</div>
				</div>
			</div>
			<el-table :data="tableData" border>
				<el-table-column prop="regNum" label="注册号">
				</el-table-column>
				<el-table-column prop="name" label="姓名">
				</el-table-column>
				<el-table-column prop="gender" label="性别">
					<template slot-scope="scope">
						<span>{{scope.row.gender == true?"男":"女"}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="studentId" label="学号">
				</el-table-column>
				<el-table-column prop="faculty" label="学院班级">
				</el-table-column>
				<el-table-column prop="workingHours" label="总工时">
				</el-table-column>
				<el-table-column prop="action" label="操作">
					<template slot-scope="scope">
						<div class="table-action">
							<span @click="actionFn('detail', scope)">详情</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="common-pageNation">
				<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
					:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		studentManhour,
		manhourFillListDownLoad,
	} from "@/api/manhour";
	export default {
		data() {
			return {
				searchForm: {},
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				showBatchSearch: false
			};
		},
		mounted() {
			this.getStudentManhour()
		},
		methods: {
			downLoadFillList() {
				let params = JSON.parse(JSON.stringify(this.searchForm))
				delete params.time
				manhourFillListDownLoad(params).then((res) => {
					const blob = new Blob([res], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
					// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
					if ('download' in document.createElement('a')) {
						// 非IE下载
						const elink = document.createElement('a')
						elink.download = "学生工时.xlsx"
						elink.style.display = 'none'
						elink.href = URL.createObjectURL(blob)
						document.body.appendChild(elink)
						elink.click()
						URL.revokeObjectURL(elink.href) // 释放URL 对象
						document.body.removeChild(elink)
					} else {
						// IE10+下载
						navigator.msSaveBlob(blob, "学生工时.xlsx")
					}
				})
			},
			// 获取学生工时表
			getStudentManhour() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				if (this.searchForm.name) {
					this.$set(params, "name", this.searchForm.name)
				}
				if (this.searchForm.regNum) {
					this.$set(params, "regNum", this.searchForm.regNum)
				}
				if (this.searchForm.studentId) {
					this.$set(params, "studentId", this.searchForm.studentId)
				}
				if (this.searchForm.time) {
					this.$set(params, "activityStartDay", this.searchForm.activityStartDay)
					this.$set(params, "activityEndDay", this.searchForm.activityEndDay)
				}
				if (this.searchForm.activityName) {
					this.$set(params, "activityName", this.searchForm.activityName)
				}
				studentManhour(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			dateChange(value) {
				if (value) {
					this.searchForm.activityStartDay = value[0];
					this.searchForm.activityEndDay = value[1];
				} else {
					this.searchForm.activityStartDay = "";
					this.searchForm.activityEndDay = "";
				}
			},
			search() {
				this.pageIndex = 1;
				this.getStudentManhour();
			},
			reset() {
				this.pageIndex = 1;
				this.searchForm = {};
				this.getStudentManhour();
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getStudentManhour();
			},
			actionFn(type, scope) {
				switch (type) {
					case "detail":
						this.$router.push({
							path: `/studentManHour/detail?manhourId=${scope.row.id}`
						})
						break;
					case "showBatchSearch":
						this.showBatchSearch = true
						break;
					case "hideBatchSearch":
						this.showBatchSearch = false
						break;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #EDF1F7;
		border-left: 0;
	}

	/deep/.common-row .el-textarea__inner {
		resize: none;
		height: 72px;
		border: 0;
	}

	/deep/.el-button {
		height: 36px;
	}

	/deep/.common-row-btn .el-button--default {
		border: 1px solid #DCDFE6 !important;
		color: #606266 !important;
	}

	.flex-start {
		display: flex;
		align-items: flex-start;
	}

	.flex-between {
		display: flex;
		justify-content: space-between;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}

	.studentManHour {
		display: flex;
		flex-direction: column;

		.studentManHour-data {
			display: flex;
			flex-direction: column;
			padding: 0 20px;

			.common-row {
				margin: 16px 0;

				.common-row-item {
					margin-right: 20px;

					.common-row-ipt {
						flex: 1;
						height: 36px;
						line-height: 36px;
					}

					.common-row-textarea {
						flex: 1;
						height: 72px;
						line-height: 72px;
						border: 1px solid #EDF1F7;
						border-left: 0;
					}
				}

				.common-row-btn {
					display: flex;
				}
			}
		}
	}
</style>
